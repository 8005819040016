import { Navigate, Outlet } from 'react-router-dom';

import { Permission, hasSession } from 'lib/contextual';

import checkPermission from './checkPermission';

interface RouteGateProps {
  permission: string;
  userPermissions: Permission[];
}

export const RouteGate = ({ permission, userPermissions }: RouteGateProps) => {
  const hasPermission = checkPermission(permission, userPermissions);

  if (!hasSession()) return <Navigate to={'/login'} />;

  if (hasPermission) return <Outlet />;

  return <Navigate to={'/'} />;
};

export default RouteGate;
