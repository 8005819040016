import { useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';

import { onlinePaymentSlip } from 'services/onlinePaymentSlip';

export const useOnlineBatches = ({
  code,
  date,
  status,
}: {
  code: string;
  date: string;
  status: string;
}) => {
  const { onlineBatches } = onlinePaymentSlip();
  const {
    data: allBatches,
    error,
    ...rest
  } = useInfiniteQuery(
    ['online-batches'],
    (page) => onlineBatches({ code, date, status, ...page }),
    {
      getNextPageParam: (data) => {
        if (data.items.length === 0) return undefined;
        return data.meta.currentPage + 1;
      },
    }
  );

  const allValues = useMemo(
    () => allBatches?.pages.flatMap(({ items }) => items),
    [allBatches]
  );
  return {
    allBatches: allValues?.length ? allValues : [],
    rest,
  };
};
