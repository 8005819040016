import api from 'services/api';
import permissionMock from 'services/permissionMock';

import { setUserSession } from 'lib/contextual/Session';

import { AUTH } from 'constants/endpoints';

export type SignIn = {
  email: string;
  password: string;
};

export const useSignIn = () => {
  const handleAuthenticate = async ({ email, password }: SignIn) => {
    try {
      const response = await api.postRequest(AUTH.SIGN_IN, { email, password });

      // TODO - change mock implementation to receive data from backend
      const permissions = response.data.role.map((role: any) =>
        permissionMock.getPermission(role.name)
      );

      setUserSession({
        cognitoId: response.data.cognitoId,
        email: response.data.email,
        name: response.data.name,
        avatar: '',
        roles: response.data.role,
        branchesIds: response.data.branchesIds,
        associatesUpdateResolveds: [],
        permissions,
      });

      return { isLogged: true };
    } catch (err) {
      return { isLogged: false };
    }
  };

  return { handleAuthenticate };
};
