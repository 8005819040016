import { AxiosInstance } from 'axios';

import api from 'services/api';

import { clear } from 'hooks/useLocalStorage';

import { AUTH } from 'constants/endpoints';
import { Urls } from 'constants/urls';

let refreshing = false;
const UNAUTHORIZED = 401;
export const refreshUserAuthToken = (instance: AxiosInstance) => {
  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.config?.url?.match(/refresh-token/)) {
        clearAndRedirectToHome();
      }

      if (
        (error.response?.status ?? UNAUTHORIZED) === UNAUTHORIZED &&
        !refreshing
      ) {
        refreshing = true;
        await refreshToken();
        refreshing = false;
      }

      return Promise.reject(error);
    }
  );
};

const refreshToken = async () =>
  await api.postRequest(AUTH.REFRESH, {
    email: 'no-reply@grupozelo.com',
    system: 'plataforma',
  });

const clearAndRedirectToHome = () => {
  clear();
  window.location.href = Urls.Login;
};
