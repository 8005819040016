import { Root } from '@radix-ui/react-alert-dialog';
import { useEffect, useRef, useState } from 'react';
import { useDebounce } from 'react-use';

import { AlertType, useAlert } from 'contexts/AlertContext';

import { ReactComponent as AlertError } from 'assets/images/AlertError.svg';
import { ReactComponent as AlertSucess } from 'assets/images/AlertSuccess.svg';
import { ReactComponent as AlertWarning } from 'assets/images/AlertWarning.svg';
import { ReactComponent as Close } from 'assets/images/Close.svg';

import * as S from './AlertDialog.styles';

export const AlertIcon = ({
  type,
  svgProps,
}: {
  type?: AlertType;
  svgProps?: React.SVGProps<SVGSVGElement>;
}) => {
  if (type === 'success') return <AlertSucess {...svgProps} />;
  if (type === 'error') return <AlertError {...svgProps} />;
  if (type === 'warn') return <AlertWarning {...svgProps} />;
  return null;
};

interface AlertDialogProps {
  delay?: number;
}

const AlertDialog = ({ delay = 5000 }: AlertDialogProps) => {
  const modalRef = useRef(null);
  const {
    isOpen,
    message,
    title,
    type,
    sizeMessage,
    delay: propsDelay,
    setVisible,
  } = useAlert();
  const [isClosing, setIsClosing] = useState(false);

  const handleClose = () => {
    if (isOpen) {
      setIsClosing(true);

      setTimeout(() => {
        setVisible(false);
        setIsClosing(false);
      }, 300);
    }
  };

  const handleOnOpenChange = (open: boolean) => {
    if (type !== 'loading') {
      if (open) {
        setVisible(true);
      } else {
        handleClose();
      }
    }
  };

  useDebounce(
    () => {
      if (isOpen && type !== 'loading') {
        handleClose();
      }
    },
    propsDelay || delay,
    [isOpen]
  );

  useEffect(() => {
    if (modalRef.current !== null && isOpen) {
      setVisible(false);
    }
  }, [isOpen, modalRef, setVisible]);

  return (
    <Root open={isOpen} onOpenChange={handleOnOpenChange}>
      <S.Overlay />
      <S.Content ref={modalRef} $isClosing={isClosing}>
        <S.Header $isClosing={isClosing}>
          <AlertIcon type={type} />
          <S.Title $hasMessage={!!message} $isClosing={isClosing}>
            {title}
          </S.Title>
          {message && (
            <S.Description $isClosing={isClosing} $sizeMessage={sizeMessage}>
              {message}
            </S.Description>
          )}
          {type === 'loading' && <S.Loading />}
          {type === 'loading' && (
            <S.Close onClick={handleClose}>
              <Close />
            </S.Close>
          )}
        </S.Header>
      </S.Content>
    </Root>
  );
};

export default AlertDialog;
