import styled, {
  css,
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from 'styled-components';

interface WrapperProps {
  hasErrors?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  fullWidth?: boolean;
  isAccordionForm?: boolean;
}

interface InputProps {
  filled?: boolean;
  status?: TextVariantKeys;
}

interface SelectProps {
  hasBorder?: boolean;
}

interface ToggleShowButtonProps {
  flipAdornment?: boolean;
  show: boolean;
  disabled?: boolean;
}

type TextVariant = FlattenInterpolation<ThemeProps<DefaultTheme>>;
export type TextVariantKeys = keyof TextVariants;

interface OptionProps {
  isSelected?: boolean;
  isActive?: boolean;
  hasDarkBackground?: boolean;
  status?: TextVariantKeys;
}

interface TextVariants {
  active: TextVariant;
  inactive: TextVariant;
  ATIVO: TextVariant;
  INATIVO: TextVariant;
  Ativo: TextVariant;
  Cancelado: TextVariant;
  Suspenso: TextVariant;
}

interface LabelProps {
  hasDarkBackground: boolean;
}

const textVariants: TextVariants = {
  active: css`
    color: ${({ theme }) => theme.new.colors.status.success};
    text-transform: uppercase;
  `,
  inactive: css`
    color: ${({ theme }) => theme.new.colors.status.error};
    text-transform: uppercase;
  `,
  ATIVO: css`
    color: ${({ theme }) => theme.new.colors.status.success};
  `,
  INATIVO: css`
    color: ${({ theme }) => theme.new.colors.status.error};
  `,
  Ativo: css`
    color: ${({ theme }) => theme.new.colors.status.success};
  `,
  Cancelado: css`
    color: ${({ theme }) => theme.new.colors.status.error};
  `,
  Suspenso: css`
    color: ${({ theme }) => theme.new.colors.status.alert};
  `,
};

export const Select = styled.div<SelectProps>`
  ${({ theme, hasBorder }) => css`
    width: 100%;
    min-height: 44px;
    padding: 14px ${theme.new.spacing.lg};

    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 6px;
    cursor: pointer;

    border: ${hasBorder
      ? `1px solid ${theme.new.colors.neutrals[600]}`
      : 'none'};
    background: ${theme.new.colors.neutrals[0]};
    border-radius: ${theme.new.shapes.borderRadius.lg};

    & svg {
      font-size: ${theme.new.typography.size.default};
      transition: transform 0.5s;
    }
  `};
`;

export const Wrapper = styled.div<WrapperProps>`
  ${({ fullWidth = false }) => css`
    display: flex;
    flex-direction: column;
    flex: ${fullWidth ? '1' : 'auto'};
  `}

  button {
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    margin: 0;
    padding: 0;
    transition: opacity 0.2s ease-in-out;

    &:disabled {
      cursor: not-allowed;
    }
  }

  &:focus-within {
    & ${Select} {
      border: 1px solid ${({ theme }) => theme.new.colors.primary.main};
    }
  }

  ${({ theme, hasErrors, disabled, readOnly, isAccordionForm }) => css`
    ${hasErrors &&
    css`
      ${Select} {
        border: 1px solid ${theme.new.colors.status.error};
        background: ${theme.new.colors.neutrals[0]};
      }
    `}

    ${!isAccordionForm &&
    disabled &&
    css`
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.3;
    `}

      ${isAccordionForm &&
    disabled &&
    css`
      ${Select} {
        cursor: not-allowed;
        pointer-events: none;
        opacity: 0.5;
        background: #dddff1;
        border: none;
      }

      ${Input} {
        color: ${theme.new.colors.neutrals[700]};
        font-size: ${theme.new.typography.size.sm};
      }
    `}

      ${isAccordionForm &&
    css`
      ${Input} {
        font-size: ${theme.new.typography.size.sm};
      }
    `}

    ${readOnly &&
    css`
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.7;
    `}
  `}
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.new.spacing.sm};
`;

export const Label = styled.label<LabelProps>`
  ${({ theme, hasDarkBackground }) => css`
    margin-top: 5px;
    text-transform: uppercase;
    font-size: ${theme.new.typography.size.xs};
    font-weight: ${theme.typography.fontWeight.semiBold};
    color: ${hasDarkBackground
      ? theme.new.colors.neutrals[0]
      : theme.new.colors.primary.main};

    span {
      color: ${theme.new.colors.status.error};
    }
  `}
`;

export const Description = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.new.typography.size.xs};
    font-weight: ${theme.typography.fontWeight.normal};
    color: ${theme.new.colors.neutrals[700]};
  `}
`;
interface InputWrapperProps {
  multipleProps?: {
    flexColumn?: boolean;
  };
}

export const InputWrapper = styled.div<InputWrapperProps>`
  ${({ multipleProps }) => css`
    display: flex;
    flex-direction: ${multipleProps?.flexColumn ? 'column' : 'row'};
    flex-wrap: nowrap;
    align-items: ${multipleProps?.flexColumn ? 'flex-start' : 'center'};
    flex: 1;
    gap: 6px;
    overflow: hidden;
  `}
`;

export const MultipleSelectedOptionWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: ${theme.new.spacing.xs};
    flex-wrap: wrap;
    color: ${theme.new.colors.neutrals[700]};
    overflow: hidden;
    max-width: -webkit-fill-available;
    max-width: -moz-available;
  `}
`;

export const Input = styled.input<InputProps>`
  ${({ theme, disabled, readOnly, filled = false, status }) => css`
    align-items: center;
    border: none;
    background: transparent;
    outline: none;
    cursor: pointer;
    text-transform: uppercase;

    width: 100%;
    flex: 1;

    color: ${theme.new.colors.neutrals[700]};
    font-size: ${theme.new.typography.size.default};
    font-weight: ${theme.new.typography.weight.regular};
    text-align: left;
    text-overflow: ellipsis;

    ${filled &&
    css`
      color: ${theme.new.colors.primary.light};
      font-weight: ${theme.new.typography.weight.semibold};
      ${status && textVariants[status] ? textVariants[status] : null}
    `}

    ${(disabled || readOnly) &&
    css`
      cursor: not-allowed;
    `}

    -webkit-appearance: none;
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      -webkit-appearance: none;
    }
  `}
`;

export const ClearButton = styled.button`
  rotate: 45deg;
  opacity: 0.4;
  transition: opacity 0.2s ease-in-out;

  &:focus,
  &:hover {
    opacity: 0.7;
  }

  svg {
    color: ${({ theme }) => theme.new.colors.transparent};
  }
`;

export const Divider = styled.div`
  width: 1px;
  background-color: ${({ theme }) => theme.new.colors.neutrals[300]};
  align-self: stretch;
`;

export const ToggleShowButton = styled.span<ToggleShowButtonProps>`
  ${({ theme, show, disabled = false, flipAdornment }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.5s ease-in-out;

    ${
      flipAdornment &&
      show &&
      css`
        transform: scaleY(-1);
      `
    }}  

    svg {
      color: ${theme.new.colors.secondary.main};
    }

    ${
      disabled &&
      css`
        &:hover {
          cursor: not-allowed;
          pointer-events: none;
        }
      `
    }
  `}
`;

export const Options = styled.div<OptionProps>`
  ${({ theme, hasDarkBackground }) => css`
    height: auto;
    min-width: 100%;
    max-width: 120%;

    position: absolute;
    padding-right: ${theme.new.spacing.sm};
    top: calc(100% + 5px);
    left: 0;
    z-index: 9999;

    background-color: ${theme.new.colors.neutrals[0]};
    padding: ${theme.new.spacing.lg} 2px ${theme.new.spacing.lg}
      ${theme.new.spacing.lg};
    border-radius: ${theme.new.shapes.borderRadius.lg};
    box-shadow: 0px ${theme.new.spacing.xl} ${theme.new.spacing.xl2}
      ${hasDarkBackground
        ? theme.new.colors.transparent[400]
        : theme.new.colors.neutrals[100]};
  `}
`;

export const OptionsList = styled.ul`
  max-height: 200px;
  width: 100%;
  padding-right: ${({ theme }) => theme.new.spacing.sm};

  overflow-x: hidden;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 5px;
    height: 150px;
  }

  ::-webkit-scrollbar-track {
    background: rgba(145, 145, 183, 0.3);
    border-radius: 100px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(145, 145, 183, 0.5);
    border-radius: 100px;
    border: 4px solid rgba(0, 0, 0, 0);
  }
`;

export const Option = styled.li<OptionProps>`
  cursor: pointer;
  ${({ theme, isSelected = false, isActive = false, status }) => css`
    padding: ${theme.new.spacing.sm} 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${theme.new.spacing.xs};
    color: ${theme.new.colors.neutrals[700]};
    display: flex;
    align-items: center;
    border-radius: 1px;

    ${isSelected &&
    css`
      color: ${theme.new.colors.primary.main};
      font-weight: ${theme.new.typography.weight.semibold};
    `}

    ${isActive &&
    css`
      color: ${theme.new.colors.primary.main};
      font-weight: ${theme.new.typography.weight.semibold};
      border-bottom: 1px solid ${theme.new.colors.neutrals[300]};
    `}

    &:hover {
      color: ${theme.new.colors.primary.main};
      font-weight: ${theme.new.typography.weight.semibold};
    }

    ${status && textVariants[status] ? textVariants[status] : null}

    & > button {
      ${({ theme }) => css`
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background: ${theme.new.colors.primary.main};
      border-radius: ${theme.new.shapes.borderRadius.lg};
      padding: 2px;
      cursor: pointer;

      &:hover {
        background: ${theme.new.colors.primary.light};
      }

      & > svg {
        width: ${theme.new.spacing.lg};
        height: ${theme.new.spacing.lg};

        & > path {
          stroke: ${theme.new.colors.neutrals[0]};
      }
    `}
    }
  `};
`;

export const OptionLabel = styled.span`
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: hidden;
  flex-direction: row;
  text-overflow: ellipsis;
  text-transform: uppercase;
`;

export const SelectedOption = styled.span`
  ${({ theme }) => css`
    white-space: nowrap;
    overflow-x: hidden;
    overflow-y: hidden;
    text-overflow: ellipsis;
    font-weight: ${theme.new.typography.weight.semibold};
    color: ${theme.new.colors.primary.main};
  `};
`;

export const TagSelectedOption = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.new.colors.neutrals[100]};
    border-radius: ${theme.new.shapes.borderRadius.md};
    padding: 0px ${theme.new.spacing.sm};
    font-size: 10px;
    color: ${theme.new.colors.neutrals[700]};

    display: flex;
    align-items: center;
    gap: ${theme.new.spacing.xs};
    flex-wrap: nowrap;
    max-width: -webkit-fill-available;
    max-width: -moz-available;
    white-space: nowrap;
  `};
`;

export const SelectedOptionLabel = styled.span`
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Span = styled.span`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;

    color: ${theme.new.colors.neutrals[700]};
    font-size: ${theme.new.typography.size.sm};
  `};
`;
