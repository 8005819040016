import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { SignIn, useSignIn } from 'useCases/auth';

import { useLocalStorage } from 'hooks/useLocalStorage';

import {
  Credentials,
  hasSession,
  removeClaims,
  retrieveUserSession,
} from 'lib/contextual/Session';

type ResponseLogin = {
  isLogged: boolean;
};

interface Session {
  user: Credentials;
  logout: () => void;
  fetchUser: () => void;
  login: (data: SignIn) => Promise<ResponseLogin>;
  isMaster: boolean;
  isFunerManager: boolean;
  updateUser: (currentUser: Credentials) => void;
}

const initialState: Session = {
  user: {
    cognitoId: '',
    avatar: '',
    name: '',
    email: '',
    roles: [],
    branchesIds: [],
    associatesUpdateResolveds: [],
    permissions: [],
  },
  logout: () => {},
  fetchUser: () => {},
  login: async (data: SignIn) => await { isLogged: false },
  isMaster: false,
  isFunerManager: false,
  updateUser: (currentUser: Credentials) => {},
};

const AuthContext = createContext<Session>(initialState);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const { setItem } = useLocalStorage();
  const [user, setUser] = useState<Credentials>({
    name: '',
    email: '',
    avatar: '',
    roles: [],
    cognitoId: '',
    branchesIds: [],
    associatesUpdateResolveds: [],
    permissions: [],
  });
  const [isMaster, setIsMaster] = useState(false);
  const [isFunerManager, setIsFunerManager] = useState(false);
  const callFirstTime = useRef(true);

  const { handleAuthenticate } = useSignIn();

  const fetchUser = () => {
    const user = retrieveUserSession();
    setItem('cognitoId', user.cognitoId);
    setUser(user as Credentials);
  };

  const updateUser = (currentUser: Credentials) => {
    setUser(currentUser);
    setItem('cognitoId', currentUser.cognitoId);
    setIsMaster(currentUser?.roles?.some((role) => role.name === 'master'));
  };

  const login = async ({ email, password }: SignIn) => {
    const { isLogged } = await handleAuthenticate({ email, password });

    if (isLogged) {
      fetchUser();
    }

    return { isLogged };
  };

  const logout = () => {
    setUser({
      name: '',
      email: '',
      avatar: '',
      roles: [],
      cognitoId: '',
      branchesIds: [],
      associatesUpdateResolveds: [],
      permissions: [],
    });
    removeClaims();
  };

  useEffect(() => {
    callFirstTime.current = !!user.name;
  }, [user.name]);

  useEffect(() => {
    if (hasSession()) {
      fetchUser();
    }
  }, []);

  useEffect(() => {
    if (user) {
      setIsMaster(user?.roles?.some((role) => role.name === 'master'));
      setIsFunerManager(
        user?.roles?.some((role) => role.name === 'gestor de unidade sisfuner')
      );
    }
  }, [user]);

  return (
    <AuthContext.Provider
      value={{
        user,
        logout,
        fetchUser,
        login,
        isMaster,
        isFunerManager,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useSession = () => useContext(AuthContext);
