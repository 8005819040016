import { useSession } from 'contexts';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { BatchType } from 'types/PaymentSlip';

import { useBatches } from 'useCases/paymentSlip';

import { pickInitialBatchType } from 'components/contextual/createPaymentSlip/selectOptions';
import HomeContent from 'components/contextual/homeContent';
import LotListTable from 'components/contextual/lotListTable';
import { Grid, Row } from 'components/shared';
import Divider from 'components/shared/core/divider';
import RoundedIcon from 'components/shared/core/roundedIcon';
import ButtonLink from 'components/shared/forms/ButtonLink';
import CustomSelect from 'components/shared/forms/CustomSelect';
import Input from 'components/shared/forms/Input';
import Breadcrumb, {
  BreadcrumbLink,
} from 'components/shared/newCore/Breadcrumb';
import Button from 'components/shared/newForms/Button';

import { Urls } from 'constants/urls';

import { ReactComponent as ArrowRight } from 'assets/images/ArrowRight.svg';

import * as S from './paymentSlip.styles';
import { selectBatchOptions, selectStatusOptions } from './selectOptions';

const PaymentSlip = () => {
  const history = useNavigate();
  const [batch, setBatchId] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [date, setDate] = useState('');
  const [batchType, setBatchType] = useState<BatchType | ''>('');
  const [isMasterUser, setIsMasterUser] = useState(false);
  const intl = useIntl();
  const { user } = useSession();
  const { allBatches, rest } = useBatches({
    code: batch.replace(/[a-zA-Z]+/gi, ''),
    date,
    status: selectedStatus,
    batchType,
    isMasterUser,
  });
  const breadcrumbLinks: BreadcrumbLink[] = [
    {
      label: intl.formatMessage({ id: 'breadcrumb.homePage' }),
      redirectTo: Urls.HomePage,
    },
    {
      label: intl.formatMessage({ id: 'breadcrumb.paymentSlipMenu' }),
      redirectTo: Urls.menuPaymentSlip,
    },
    {
      label: intl.formatMessage({ id: 'breadcrumb.paymentSlip' }),
      redirectTo: Urls.paymentSlip,
    },
  ];

  const refetch = () => rest.refetch();

  const removeFilter = () => {
    setBatchId('');
    setSelectedStatus('');
    setDate('');
  };

  useEffect(() => {
    const initialBatchType = user.permissions
      .map(pickInitialBatchType)
      .filter((t) => Boolean(t))[0];
    setIsMasterUser(user.permissions.some((p) => p.name === 'master'));
    setBatchType(initialBatchType);
  }, [user.permissions]);

  return (
    <HomeContent>
      <Breadcrumb path={breadcrumbLinks} />
      <S.Title>
        <S.MobileControl>
          {intl.formatMessage({
            id: 'paymentSlip.title',
          })}
        </S.MobileControl>
      </S.Title>
      <Grid>
        <Row span="3">
          <CustomSelect
            id="batchType"
            inputName="batchType"
            inputValue={batchType}
            label={intl.formatMessage({
              id: 'createPaymentSlip.form.label.batchType',
            })}
            options={selectBatchOptions(intl)}
            setValue={(_, v) => setBatchType(v)}
            isBordered
            disabled={!isMasterUser}
          />
        </Row>
      </Grid>

      <Divider />

      <>
        <S.Header>
          <Row span="3">
            <Input
              onChange={(e) => {
                setBatchId(e.target.value);
              }}
              value={batch}
              variant="lighter"
              label={intl.formatMessage({
                id: 'paymentSlip.form.label.input',
              })}
            />
          </Row>

          <Row span="4">
            <CustomSelect
              id="statusInputType"
              inputName="statusInputType"
              inputValue={selectedStatus}
              label={intl.formatMessage({
                id: 'paymentSlip.form.label.selectLabel',
              })}
              options={selectStatusOptions(intl)}
              setValue={(_, v) => setSelectedStatus(v)}
              isBordered
            />
          </Row>

          <Row span="3">
            <Input
              onChange={(e) => setDate(e.target.value)}
              value={date}
              variant="lighter"
              type="date"
              label={intl.formatMessage({
                id: 'paymentSlip.form.label.creationDate',
              })}
            />
          </Row>

          <Row>
            <Button paymentSlipPage onClick={refetch}>
              {intl.formatMessage({
                id: 'paymentSlip.button.applyFilter',
              })}
            </Button>
          </Row>
          <Row>
            <Button paymentSlipPage onClick={removeFilter}>
              {intl.formatMessage({
                id: 'paymentSlip.button.removeFilter',
              })}
            </Button>
          </Row>
        </S.Header>

        <LotListTable
          handleLoadMore={() => {
            rest.fetchNextPage();
          }}
          isLoadingFilter={rest.isFetchingNextPage}
          isLoading={rest.isFetching}
          isCompleteLoad={!rest.hasNextPage}
          data={allBatches}
        />

        <Divider />

        <S.Footer>
          <ButtonLink
            onClick={() => history(Urls.createPaymentSlip)}
            type="button"
            variant="lighter"
          >
            {intl.formatMessage({ id: 'batchList.button.newBatch' })}
            <RoundedIcon>
              <ArrowRight />
            </RoundedIcon>
          </ButtonLink>
        </S.Footer>
      </>
    </HomeContent>
  );
};

export default PaymentSlip;
