import { IntlShape, useIntl } from 'react-intl';

import { useOnlineBatches } from 'useCases/onlinePaymentSlip/useOnlineBatches';

import { Row, SelectItem } from 'components/shared';
import CustomSelect from 'components/shared/forms/CustomSelect';
import Input from 'components/shared/forms/Input';
import Button from 'components/shared/newForms/Button';

import { BatchBankSlipMailingStatusEnum } from 'constants/enums';

interface OnlinePaymentSlipFilterProps {
  setBatchId: (e: string) => void;
  setDate: (e: string) => void;
  setSelectedStatus: (v: string) => void;
  batch: string;
  selectedStatus: string;
  date: string;
}

const OnlinePaymentSlipFilter = ({
  setBatchId,
  setDate,
  setSelectedStatus,
  batch,
  selectedStatus,
  date,
}: OnlinePaymentSlipFilterProps) => {
  const intl = useIntl();
  const { rest } = useOnlineBatches({
    code: batch,
    date,
    status: selectedStatus,
  });
  const refetch = () => rest.refetch();

  const removeFilter = () => {
    setBatchId('');
    setSelectedStatus('');
    setDate('');
  };

  const statusList = Object.values(BatchBankSlipMailingStatusEnum);
  const selectStatusOptions = (intl: IntlShape): SelectItem[] =>
    statusList.map((value) => ({
      label: intl.formatMessage({ id: `bankSlipStatus.${value}` }),
      value,
    }));

  return (
    <>
      <Row span="3">
        <Input
          onChange={(e) => {
            setBatchId(e.target.value);
          }}
          value={batch}
          variant="lighter"
          label={intl.formatMessage({
            id: 'paymentSlip.form.label.input',
          })}
        />
      </Row>

      <Row span="4">
        <CustomSelect
          id="statusInputType"
          inputName="statusInputType"
          inputValue={selectedStatus}
          label={intl.formatMessage({
            id: 'paymentSlip.form.label.selectLabel',
          })}
          options={selectStatusOptions(intl)}
          setValue={(_, v) => setSelectedStatus(v)}
          isBordered
        />
      </Row>

      <Row span="3">
        <Input
          onChange={(e) => setDate(e.target.value)}
          value={date}
          variant="lighter"
          type="date"
          label={intl.formatMessage({
            id: 'paymentSlip.form.label.creationDate',
          })}
        />
      </Row>

      <Row>
        <Button paymentSlipPage onClick={refetch}>
          {intl.formatMessage({
            id: 'paymentSlip.button.applyFilter',
          })}
        </Button>
      </Row>
      <Row>
        <Button paymentSlipPage onClick={removeFilter}>
          {intl.formatMessage({
            id: 'paymentSlip.button.removeFilter',
          })}
        </Button>
      </Row>
    </>
  );
};

export default OnlinePaymentSlipFilter;
