import { useAlert } from 'contexts';
import { useFormik } from 'formik';
import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { CreateOnlinePaymentSlipForm } from 'types/PaymentSlip';

import { useOnlineBatchCreator } from 'useCases/onlinePaymentSlip/useOnlineBatchCreator';

import CreateNewOnlineBatchReadjustments from 'components/contextual/createOnlinePaymentSlip';
import { useCreateOnlinePaymentSlipValidation } from 'components/contextual/createOnlinePaymentSlip/formValidation';
import {
  getInitialValues,
  mapToApi,
} from 'components/contextual/createOnlinePaymentSlip/mappers';
import { createBreadcumbLinks } from 'components/contextual/createOnlinePaymentSlip/utils';
import HomeContent from 'components/contextual/homeContent';
import AlertDialog from 'components/shared/core/alertDialog';
import Divider from 'components/shared/core/divider';
import RoundedIcon from 'components/shared/core/roundedIcon';
import ButtonLink from 'components/shared/forms/ButtonLink';
import Breadcrumb from 'components/shared/newCore/Breadcrumb';

import { Urls } from 'constants/urls';

import { ReactComponent as ArrowRight } from 'assets/images/ArrowRight.svg';

import * as C from './CreateOnlinePaymentSlip.styles';

const CreateOnlinePaymentSlip = () => {
  const intl = useIntl();
  const history = useNavigate();
  const { showAlert, setVisible, setClear } = useAlert();
  const breadcumbLinks = useMemo(() => createBreadcumbLinks(intl), [intl]);
  const formik = useFormik<CreateOnlinePaymentSlipForm>({
    initialValues: getInitialValues(),
    validationSchema: useCreateOnlinePaymentSlipValidation(),
    onSubmit: async () => {
      showAlert({
        type: 'loading',
        title: intl.formatMessage({
          id: 'createOnlinePaymentSlip.form.saveBatch',
        }),
      });
      await mutate();
    },
  });

  const { mutate, isSuccess } = useOnlineBatchCreator(
    mapToApi(formik.values),
    formik.values.archive!
  );

  const handleArchive = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      formik.setFieldValue('archive', file);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setClear();
      setTimeout(() => {
        showAlert({
          type: 'success',
          title: intl.formatMessage({
            id: 'createOnlinePaymentSlip.form.submitEditSuccess',
          }),
        });
      });

      setTimeout(() => {
        setVisible(false);
        history(Urls.onlinePaymentSlip);
      }, 3000);
    }
  }, [isSuccess]);

  return (
    <HomeContent>
      <Breadcrumb path={breadcumbLinks} />
      <C.Header>
        <C.Title>
          {intl.formatMessage({
            id: 'createOnlinePaymentSlip.newOnlinePaymentSlip',
          })}
        </C.Title>
      </C.Header>
      <Divider />
      <>
        <CreateNewOnlineBatchReadjustments
          handleFile={handleArchive}
          formik={formik}
        />
        <Divider />
        <C.Footer>
          <ButtonLink
            onClick={() => formik.handleSubmit()}
            type="button"
            variant="lighter"
          >
            {intl.formatMessage({
              id: 'createOnlinePaymentSlip.form.buttonSubmit',
            })}
            <RoundedIcon>
              <ArrowRight />
            </RoundedIcon>
          </ButtonLink>
        </C.Footer>
      </>
      <AlertDialog delay={3000} />
    </HomeContent>
  );
};

export default CreateOnlinePaymentSlip;
