import { clear, getItem, setItem } from 'hooks/useLocalStorage';

import { StatusLog } from 'constants/enums';

export interface Actions {
  [key: string]: string[];
}
export interface Role {
  name: string;
  actions: Actions;
}

type AssociateLog = {
  subscriptionCode: number;
  status: StatusLog;
};

export type PermissionRule = {
  [key: string]: string[] | PermissionRule;
};

export interface Permission {
  name: string;
  rules: PermissionRule;
}

export interface Credentials {
  name: string;
  email: string;
  cognitoId: string;
  avatar: string;
  roles: Role[];
  branchesIds: string[];
  associatesUpdateResolveds: AssociateLog[];
  permissions: Permission[];
}

const USER_ID = 'id';
const USER_EMAIL = 'email';
const USER_NAME = 'name';
const USER_AVATAR = 'avatar';
const USER_ROLES = 'roles';
const USER_BRANCH = 'branches';
const ASSOCIATES_UPDATE_RESOLVEDS = 'associatesUpdateResolveds';
const USER_PERMISSIONS = 'permissions';

export const getUserId = () => getItem(USER_ID);

export const retrieveUserSession = () => {
  const cognitoId = getItem(USER_ID);
  const email = getItem(USER_EMAIL);
  const name = getItem(USER_NAME);
  const avatar = getItem(USER_AVATAR);
  const roles = getItem(USER_ROLES);
  const branchesIds = getItem(USER_BRANCH);
  const associatesUpdateResolveds = getItem(ASSOCIATES_UPDATE_RESOLVEDS);
  const permissions = getItem(USER_PERMISSIONS);
  return {
    cognitoId,
    email,
    name,
    avatar,
    roles: roles ? JSON.parse(roles) : [],
    branchesIds: branchesIds ? JSON.parse(branchesIds) : [],
    associatesUpdateResolveds: associatesUpdateResolveds
      ? JSON.parse(associatesUpdateResolveds)
      : [],
    permissions: permissions
      ? JSON.parse(permissions)
      : { name: '', rules: {} },
  };
};

export const retriveUserPermissions = (): Permission[] => {
  const permissions = getItem(USER_PERMISSIONS);
  return permissions ? JSON.parse(permissions) : [];
};

export const setUserSession = (credencials: Credentials) => {
  setItem(USER_ID, credencials.cognitoId);
  setItem(USER_EMAIL, credencials.email);
  setItem(USER_NAME, credencials.name);
  setItem(USER_AVATAR, credencials.avatar);
  setItem(USER_BRANCH, JSON.stringify(credencials.branchesIds));
  setItem(USER_BRANCH, JSON.stringify(credencials.branchesIds));
  setItem(ASSOCIATES_UPDATE_RESOLVEDS, JSON.stringify([]));

  if (credencials.roles) {
    setItem(USER_ROLES, JSON.stringify(credencials.roles));
  } else {
    setItem(USER_ROLES, JSON.stringify([]));
  }

  if (credencials.permissions) {
    setItem(USER_PERMISSIONS, JSON.stringify(credencials.permissions));
  } else {
    setItem(USER_PERMISSIONS, JSON.stringify({ name: '', rules: {} }));
  }
};

export const removeClaims = () => clear();

export const hasSession = () => !!getUserId();
