import {
  CemeteriesResponse,
  FuneralLocationResponse,
  ThanatoLaboratoriesResponse,
} from 'types/AddServiceSisfuner';
import { ReversalSisfuner } from 'types/CashflowReversalSisfuner';
import { PaginatedParams } from 'types/PaginatedResponse';
import {
  CreateEmployeesParams,
  DeathRecordRequest,
  Doctor,
  DoctorsParamType,
  FuneralHomesParamType,
  FuneralHomesResponse,
  GetClients,
  GetClientsResponse,
  GetEmployeesParamType,
  GetPlacesDeathParamType,
  GetProductsParams,
  HierarchiesParamType,
  HierarchiesResponse,
  Installment,
  PaymentDeadlineInvoicesResponse,
  PositionsParamType,
  PositionsResponse,
  PostPaymentLinkRequest,
  Product,
  RegistryOfficesParamType,
  RegistryOfficesResponse,
  ReligionsParamType,
  ReligionsResponse,
  ResponseEmployees,
  ResponsePlacesOfDeath,
  ResponseWithPagination,
  SkinColorsParamType,
  SkinColorsResponse,
  StockMovementId,
  StockMovementRequest,
  StockTransferResponse,
  StockTransferSolicitationParams,
  UpdateEmployeesParams,
  UpdateStockMovementRequest,
  UserResponse,
  UsersParamsType,
  UsersResponse,
} from 'types/Sisfuner';

import api from 'services/api';

import { getURLWithParams } from 'lib/contextual/Url';

import {
  SISFUNER_FUNERAL_AGENT,
  SISFUNER_INSTALLMENTS,
  SISFUNER_MANAGEMENT,
  SISFUNER_WORK_ORDER,
  USERS,
} from 'constants/endpoints';

export const sisfuner = () => {
  const getStockQuantity = async (
    productId: number,
    funeralHomeId: number
  ): Promise<{ quantity: number }> => {
    const url = SISFUNER_MANAGEMENT.GET_STOCK_QUANTITY(
      productId,
      funeralHomeId
    );
    const result = await api.getRequest(url);
    return result?.data;
  };

  const getFuneralHomes = async (
    params: FuneralHomesParamType
  ): Promise<ResponseWithPagination<FuneralHomesResponse>> => {
    const queryParams = Object.fromEntries(
      Object.entries({
        ...params,
      }).filter(([_key, value]) => value !== undefined)
    );

    const url = getURLWithParams(
      SISFUNER_MANAGEMENT.GET_FUNERAL_HOMES,
      queryParams
    );
    const result = await api.getRequest(url);

    return result.data;
  };

  const getHierarchies = async (
    params: HierarchiesParamType
  ): Promise<ResponseWithPagination<HierarchiesResponse>> => {
    const queryParams = Object.fromEntries(
      Object.entries({
        ...params,
      }).filter(([_key, value]) => value !== undefined)
    );

    const url = getURLWithParams(
      SISFUNER_MANAGEMENT.GET_HIERARCHY,
      queryParams
    );
    const result = await api.getRequest(url);

    return result.data;
  };

  const getPositions = async (
    params: PositionsParamType
  ): Promise<ResponseWithPagination<PositionsResponse>> => {
    const queryParams = Object.fromEntries(
      Object.entries({
        ...params,
      }).filter(([_key, value]) => value !== undefined)
    );

    const url = getURLWithParams(
      SISFUNER_MANAGEMENT.GET_POSITIONS,
      queryParams
    );
    const result = await api.getRequest(url);

    return result.data;
  };

  const getUsers = async (
    params: UsersParamsType
  ): Promise<ResponseWithPagination<UsersResponse>> => {
    const queryParams = Object.fromEntries(
      Object.entries({
        ...params,
      }).filter(([_key, value]) => value !== undefined)
    );

    const url = getURLWithParams(SISFUNER_MANAGEMENT.USERS, queryParams);
    const result = await api.getRequest(url);

    return result.data;
  };

  const getUserByEmail = async (email: string): Promise<UserResponse> => {
    const url = getURLWithParams(USERS.BY_EMAIL_SYNC(email));
    const result = await api.getRequest(url);
    return result.data;
  };

  const getEmployees = async (
    params: GetEmployeesParamType
  ): Promise<ResponseWithPagination<ResponseEmployees>> => {
    const queryParams = Object.fromEntries(
      Object.entries({
        ...params,
      }).filter(([_key, value]) => value !== undefined)
    );

    const url = getURLWithParams(
      SISFUNER_MANAGEMENT.GET_EMPLOYEES,
      queryParams
    );
    const result = await api.getRequest(url);

    return result.data;
  };

  const getEmployeesFuneralHomes = async (
    email: string
  ): Promise<{ 'funeral-homes': FuneralHomesResponse[] }> => {
    const url = getURLWithParams(
      SISFUNER_MANAGEMENT.GET_EMPLOYEES_FUNERAL_HOMES(email)
    );
    const result = await api.getRequest(url);

    return result?.data;
  };

  const createEmployees = async (
    data: CreateEmployeesParams
  ): Promise<ResponseEmployees> => {
    const response = await api.postRequest(
      SISFUNER_MANAGEMENT.POST_EMPLOYEES,
      data
    );
    return response.data;
  };

  const updateEmployees = async (
    id: number,
    data: UpdateEmployeesParams
  ): Promise<ResponseEmployees> => {
    const response = await api.patchRequest(
      SISFUNER_MANAGEMENT.PATCH_EMPLOYEE(id),
      data
    );
    return response.data;
  };

  const getEmployee = async (id: number): Promise<ResponseEmployees> => {
    const response = await api.getRequest(SISFUNER_MANAGEMENT.GET_EMPLOYEE(id));
    return response.data;
  };

  const getEmployeeByEmail = async (
    email: string
  ): Promise<ResponseEmployees> => {
    const response = await api.getRequest(
      SISFUNER_MANAGEMENT.GET_EMPLOYEE_BY_EMAIL(email)
    );
    return response.data;
  };

  const getPaymentDeadlineInvoices = async () => {
    const result = await api.getRequest(
      SISFUNER_MANAGEMENT.GET_PAYMENT_DEADLINE_INVOICES
    );
    return result.data;
  };

  const createPaymentDeadlineInvoices = async (
    data: PaymentDeadlineInvoicesResponse
  ) => {
    const url = getURLWithParams(
      SISFUNER_MANAGEMENT.POST_PAYMENT_DEADLINE_INVOICES
    );
    const result = await api.postRequest(url, data);

    return result.data;
  };

  const updatePaymentDeadlineInvoices = async (
    data: PaymentDeadlineInvoicesResponse
  ) => {
    if (data.id) {
      const response = await api.putRequest(
        SISFUNER_MANAGEMENT.PUT_PAYMENT_DEADLINE_INVOICES(data.id),
        data
      );

      return response.data;
    }
  };

  const postReprocessInvoices = async (invoiceId: string) => {
    const response = await api.postRequest(
      SISFUNER_WORK_ORDER.POST_WORK_ORDER_INVOICE_REPROCESS(invoiceId),
      {}
    );
    return response;
  };

  const getRegistryOffices = async (
    params: RegistryOfficesParamType
  ): Promise<ResponseWithPagination<RegistryOfficesResponse>> => {
    const url = getURLWithParams(
      SISFUNER_FUNERAL_AGENT.GET_REGISTRY_OFFICES,
      params
    );
    const result = await api.getRequest(url);

    return result.data;
  };

  const getReligions = async (
    params: ReligionsParamType
  ): Promise<ResponseWithPagination<ReligionsResponse>> => {
    const url = getURLWithParams(SISFUNER_FUNERAL_AGENT.GET_RELIGIONS, params);
    const result = await api.getRequest(url);

    return result.data;
  };

  const getSkinColors = async (
    params: SkinColorsParamType
  ): Promise<ResponseWithPagination<SkinColorsResponse>> => {
    const url = getURLWithParams(
      SISFUNER_FUNERAL_AGENT.GET_SKIN_COLORS,
      params
    );
    const result = await api.getRequest(url);

    return result.data;
  };

  const getDoctors = async (
    params: DoctorsParamType
  ): Promise<ResponseWithPagination<Doctor>> => {
    const url = getURLWithParams(SISFUNER_FUNERAL_AGENT.DOCTORS_URL, params);
    const result = await api.getRequest(url);

    return result.data;
  };

  const editDeathRecord = async (data: Partial<DeathRecordRequest>) => {
    const { id, funeralHome, ...values } = data;
    const url = getURLWithParams(
      SISFUNER_FUNERAL_AGENT.PUT_DEATH_RECORD(Number(id), Number(funeralHome))
    );
    const result = await api.putRequest(url, values);

    return result.data;
  };

  const getFuneralLocation = async (
    params: PaginatedParams
  ): Promise<ResponseWithPagination<FuneralLocationResponse>> => {
    const url = getURLWithParams(
      SISFUNER_FUNERAL_AGENT.GET_FUNERAL_LOCATION,
      params
    );
    const response = await api.getRequest(url);
    return response.data;
  };

  const getCemeteries = async (
    params: PaginatedParams
  ): Promise<ResponseWithPagination<CemeteriesResponse>> => {
    const url = getURLWithParams(SISFUNER_FUNERAL_AGENT.GET_CEMETERIES, params);
    const response = await api.getRequest(url);
    return response.data;
  };

  const getThanatoLaboratories = async (
    params: PaginatedParams
  ): Promise<ResponseWithPagination<ThanatoLaboratoriesResponse>> => {
    const url = getURLWithParams(
      SISFUNER_FUNERAL_AGENT.GET_THANATO_LOCATION,
      params
    );
    const response = await api.getRequest(url);
    return response.data;
  };

  const getProducts = async (params: GetProductsParams): Promise<Product[]> => {
    const filteredParams = Object.fromEntries(
      Object.entries({
        ...params,
      }).filter(([_key, value]) => value !== undefined)
    );
    const url = getURLWithParams(
      SISFUNER_FUNERAL_AGENT.GET_PRODUCTS,
      filteredParams
    );
    const result = await api.getRequest(url);

    return result.data;
  };

  const postPaymentLink = async (data: PostPaymentLinkRequest) => {
    const { orderNumber, funeralHomeId, paymentMethodId, ...body } = data;
    const response = await api.postRequest(
      SISFUNER_WORK_ORDER.POST_PAYMENT_LINK(
        orderNumber,
        funeralHomeId,
        paymentMethodId
      ),
      body
    );
    return response.data;
  };

  const createStockMovement = async (data: StockMovementRequest) => {
    const url = getURLWithParams(SISFUNER_MANAGEMENT.STOCK_MOVEMENT);
    const result = await api.postRequest(url, data);

    return result.data;
  };

  const getLastEntranceSisfuner = async (
    userId: string,
    funeralHomeId: string
  ) => {
    const url = getURLWithParams(SISFUNER_INSTALLMENTS.ACTIVE(userId), {
      funeralHomeId: funeralHomeId,
    });

    const { data } = await api.getRequest<Installment>(url, {
      headers: { TransactionId: new Date().getTime() },
    });
    return data;
  };

  const createInstallmentSisfuner = async (
    installment: Partial<Installment>
  ) => {
    const data = await api.postRequest(
      SISFUNER_INSTALLMENTS.CREATE,
      installment,
      {
        headers: { TransactionId: new Date().getTime() },
      }
    );
    return data;
  };

  const fetchReversalsSisfuner = async (
    installmentId: string
  ): Promise<ReversalSisfuner[]> => {
    const response = await api.getRequest(
      SISFUNER_INSTALLMENTS.REVERSAL(installmentId)
    );
    return response.data;
  };

  const reversePayments = async (body: ReversalSisfuner[]): Promise<{}> => {
    const response = await api.patchRequest(
      SISFUNER_INSTALLMENTS.REVERSE_PAYMENTS,
      body
    );
    return response.data;
  };

  const getStockMovement = async (
    params: StockTransferSolicitationParams
  ): Promise<ResponseWithPagination<StockTransferResponse>> => {
    const queryParams = Object.fromEntries(
      Object.entries({
        [params.query ? params.queryType : '']: params.query,
        [params.status ? 'status' : '']: params.status,
        [params.movementType ? 'movementType' : '']: params.movementType,
        funeralOrigin: params.funeralDestinationId,
        page: params.page,
        limit: params.limit,
      }).filter(([_key, value]) => value !== undefined)
    );

    const url = getURLWithParams(
      SISFUNER_MANAGEMENT.STOCK_MOVEMENT,
      queryParams
    );

    const result = await api.getRequest(url);

    return result?.data;
  };

  const getStockMovementById = async (id: number): Promise<StockMovementId> => {
    const response = await api.getRequest(
      SISFUNER_MANAGEMENT.GET_STOCK_MOVEMENT_BY_ID(id)
    );
    return response.data;
  };

  const getOneWorkOrderItem = async (
    orderNumber: string,
    funeralHomeId: string,
    itemCode: number
  ) => {
    const response = await api.getRequest(
      SISFUNER_WORK_ORDER.GET_ONE_WORK_ORDER_ITEM(
        orderNumber,
        funeralHomeId,
        itemCode
      )
    );
    return response;
  };

  const updateStockMovementById = async (
    id: number,
    data: UpdateStockMovementRequest
  ): Promise<UpdateStockMovementRequest> => {
    const url = getURLWithParams(
      SISFUNER_MANAGEMENT.PUT_STOCK_MOVEMENT_BY_ID(id)
    );
    const result = await api.putRequest(url, data);

    return result.data;
  };

  const createBankSlip = async (
    orderNumber: number,
    funeralHomeId: string,
    paymentMethodId: string,
    amount: string,
    seqPaymentMethod: number
  ) => {
    const url = getURLWithParams(
      SISFUNER_WORK_ORDER.CREATE_BANK_SLIP(orderNumber, funeralHomeId)
    );
    const result = await api.postRequest<Blob>(
      url,
      {
        paymentMethodId: paymentMethodId,
        amount: amount,
        seqPaymentMethod,
      },
      {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/pdf',
        },
      }
    );
    return result.data;
  };

  const getPlacesDeath = async (
    params: GetPlacesDeathParamType
  ): Promise<ResponseWithPagination<ResponsePlacesOfDeath>> => {
    const queryParams = Object.fromEntries(
      Object.entries({
        ...params,
      }).filter(([_key, value]) => value !== undefined)
    );

    const url = getURLWithParams(
      SISFUNER_FUNERAL_AGENT.GET_PLACES_OF_DEATH,
      queryParams
    );
    const result = await api.getRequest(url);

    return result.data;
  };

  const getClients = async (
    params: GetClients
  ): Promise<ResponseWithPagination<GetClientsResponse>> => {
    const queryParams = Object.fromEntries(
      Object.entries({
        ...params,
      }).filter(([_key, value]) => value !== undefined)
    );

    const url = getURLWithParams(SISFUNER_FUNERAL_AGENT.CLIENTS, queryParams);
    const result = await api.getRequest(url);

    return result.data;
  };

  return {
    getFuneralHomes,
    getProducts,
    getUsers,
    getHierarchies,
    getPositions,
    getEmployees,
    createEmployees,
    updateEmployees,
    getPaymentDeadlineInvoices,
    createPaymentDeadlineInvoices,
    updatePaymentDeadlineInvoices,
    postReprocessInvoices,
    getEmployeesFuneralHomes,
    getEmployee,
    getEmployeeByEmail,
    getRegistryOffices,
    getReligions,
    getSkinColors,
    getDoctors,
    editDeathRecord,
    getFuneralLocation,
    getCemeteries,
    getThanatoLaboratories,
    postPaymentLink,
    createStockMovement,
    getStockMovement,
    getStockMovementById,
    updateStockMovementById,
    getUserByEmail,
    getLastEntranceSisfuner,
    createInstallmentSisfuner,
    fetchReversalsSisfuner,
    reversePayments,
    createBankSlip,
    getPlacesDeath,
    getStockQuantity,
    getClients,
    getOneWorkOrderItem,
  };
};
