import { Permission, PermissionRule } from 'lib/contextual';

const isPermissionRule = (
  rule: PermissionRule | string[]
): rule is PermissionRule => typeof rule === 'object' && !Array.isArray(rule);

function isPermited(path: string, permissions: Permission): boolean {
  const pathArray = path.split('.');
  let rules: PermissionRule | string[] = permissions.rules;

  for (let i = 0; i < pathArray.length; i++) {
    if (isPermissionRule(rules) && rules[pathArray[i]] !== undefined) {
      rules = rules[pathArray[i]];
    } else if (Array.isArray(rules) && rules.includes(pathArray[i])) {
      return true;
    } else {
      return false;
    }
  }

  return true;
}

function checkPermission(
  path: string,
  permissions: Permission | Permission[]
): boolean {
  return [permissions]
    .flat()
    .some((permission) => isPermited(path, permission));
}

export default checkPermission;
