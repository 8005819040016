import styled, { css } from 'styled-components';

interface ContainerProps {
  column?: boolean;
  greaterMaxWidth?: boolean;
  marginBottom?: string;
  gap?: string;
}

export const Container = styled.div<ContainerProps>`
  ${(props) => {
    const maxWidth = props.greaterMaxWidth ? '1279px' : '800px';
    if (props.column) {
      return css`
        display: flex;
        flex-direction: column;
        gap: 10px;
      `;
    }

    return css`
      display: flex;
      flex-direction: row;
      gap: 2rem;
      white-space: nowrap;

      @media screen and (max-width: ${maxWidth}) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: ${props.gap && props.greaterMaxWidth ? '0' : '1rem'};
      }
    `;
  }}

  input {
    display: none;
  }

  input + label:before {
    content: '';
    width: 24px;
    height: 24px;
    border-radius: 12px;
    background: ${({ theme }) => theme.surface.main};
    box-shadow: inset 0px 0px 0px 2px ${({ theme }) => theme.input.radio.border};
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
    margin-bottom: 3px;
  }

  input:checked + label:before {
    box-shadow: inset 0px 0px 0px 8px
      ${({ theme }) => theme.input.radio.borderChecked};
  }

  input:checked + label {
    color: ${({ theme }) => theme.input.radio.labelChecked};
  }

  label {
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-size: ${({ theme }) => theme.fontSizes.small};
    line-height: 100%;
    font-weight: 600;
    color: ${({ theme }) => theme.input.radio.label};
    cursor: pointer;
  }

  input:disabled + label:before {
    background: ${({ theme }) => theme.input.radio.backgroundDisabled};
    cursor: not-allowed;
  }
`;
