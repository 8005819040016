import queryString from 'querystring';

const removeEmptyValuesFromObject = <T>(obj: {
  [key: string]: unknown;
}): Partial<T> =>
  Object.fromEntries(
    Object.entries(obj).filter(
      ([, value]) =>
        value !== undefined &&
        value !== null &&
        value !== '' &&
        !(
          Array.isArray(value) &&
          (value.length === 0 || value.every((item) => item === ''))
        )
    )
  ) as Partial<T>;

export const getQueryString = (obj: { [key: string]: unknown }): string => {
  const cleanedObj = removeEmptyValuesFromObject(obj);
  const queryStr = queryString.stringify(cleanedObj);

  return queryStr;
};
